import React from "react";
import { SliitWrapper } from "./Sliit.styles";

const SLIIT = () => {

  return (
    <SliitWrapper>
      <img src="img/turnkey.jpeg" />
    </SliitWrapper>
  );
};

export { SLIIT };
