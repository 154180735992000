import styled from "styled-components";
import { respondTo } from "../../../../styles/_respondTo";

export const AnimationWrapper = styled.div`


.animation{
    width: 300px;
    height: auto;
    margin: 26px 10px;

    position: absolute;
    bottom: 280px;
    left: 250px;

    ${respondTo.sm`
         width: 30px;
         height: auto;
         margin: 16px 5px;
    `}

}

`;
