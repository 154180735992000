import React from "react";
import { AnimationWrapper } from "./MovingAnimation.styles";

const MovingAnimation = () => {
  return (
    <AnimationWrapper>
      <img className='animation' src="svgs/moving.svg" alt="" />
    </AnimationWrapper>
  );
};

export { MovingAnimation };
