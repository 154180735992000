import React from "react";
import { ContactMeWrapper } from "./ContactMe.styles";
import { SectionHeading } from "../../components/Shared/SectionHeading";
import { LondonBG } from "../../components/Shared/LondonBG";
import { Container, Row, Col } from "react-bootstrap";

import ReactMapboxGl, { Layer, Feature } from "react-mapbox-gl";
import { Source } from "react-mapbox-gl";
import { SubscribeForm } from "../../components/SubscribeForm";

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1Ijoic2ludGh1MjI1IiwiYSI6ImNrYjV6ZnY0ZDAzNHcycm9iaXU3cG9tOGYifQ.HyJWr35hi873zVb2nOo-rQ",
  scrollZoom: false,
});

const RASTER_SOURCE_OPTIONS = {
  type: "raster",
  tiles: ["https://someurl.com/512/{z}/{x}/{y}"],
  tileSize: 512,
};

const ContactMe = () => {
  return (
    <ContactMeWrapper>
      <LondonBG />
      {/* <Map
        className="map-bg"
        style="mapbox://styles/mapbox/dark-v10"
        center={[51.52602, -0.0911]}
        zoom={[6]}
        containerStyle={{
          height: "100vh",
          width: "100vw",
        }}
      >
        <Source id="source_id" tileJsonSource={RASTER_SOURCE_OPTIONS} />
        <Layer
          id="3d-buildings"
          source="composite"
          source-layer="building"
          type="fill-extrusion"
          minzoom="15"
          paint={{
            "fill-extrusion-color": "#aaa",

            // use an 'interpolate' expression to add a smooth transition effect to the
            // buildings as the user zooms in
            "fill-extrusion-height": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "height"],
            ],
            "fill-extrusion-base": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "min_height"],
            ],
            "fill-extrusion-opacity": 0.6,
          }}
        ></Layer>
      </Map> */}
      <Container className="contact-content">
        <SectionHeading sectionHeading="Contact Me" />

        <div className="contact-me-content">
          <Row>
            <Col md={6}>
              <div className="map-container"></div>
            </Col>
            <Col md={6}>
              <div className="contact-form-container">
                <div className="contact-form">
                  <p>Let's Have a chat</p>
                  <p className="mailTo">
                    Shoot me a mail @{" "}
                    <a href="mailto:kslinu09@gmail.com" target="_top">
                      kslinu09@gmail.com
                    </a>
                  </p>
                  {/* <SubscribeForm></SubscribeForm> */}

                  <div className="contact-details">
                    {/* <div className="contact-row">
                      <div className="contact-img">
                        <img src="svgs/phone.svg" alt="" />
                      </div>
                      <div className="contact-val">+971 525 470 445</div>
                    </div> */}
                    <div className="contact-row">
                      <div className="contact-img">
                        <img src="svgs/location.svg" alt="" />
                      </div>
                      <div className="contact-val">
                        Business Bay,
                        <br></br>
                        Dubai
                      </div>
                    </div>
                    <div className="contact-row">
                      <div className="contact-img">
                        <img src="svgs/london.svg" alt="" />
                      </div>
                      <div className="contact-val">
                        Moving to London from June 2022
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </ContactMeWrapper>
  );
};

export { ContactMe };
