import React from "react";
import { CreativeCliksWrapper } from "./CreativeCliks.styles";

const CreativeCliks = () => {

  return (
    <CreativeCliksWrapper>
      <img src="img/CC_final.png" />
    </CreativeCliksWrapper>
  );
};

export { CreativeCliks };
