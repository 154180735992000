import styled from "styled-components";
import { respondTo } from "../../styles/_respondTo";

export const SkillsWrapper = styled.div`
  position: relative;
  overflow: hidden;

  .skills-section {
    display: flex;

    flex-wrap: wrap;
    margin: 0 -10px;
    margin-top: 50px;

    .skills-card {
      width: calc(33% - 20px);
      background-color: #e7edf8;
      margin-bottom: 54px;
      padding: 60px 30px 20px;
      position: relative;
      transition-duration: 0.3s;
      transition-property: box-shadow, transform, -webkit-transform;
      margin: 0 10px;
      margin-bottom: 40px;
      display: flex;
      flex-wrap: wrap;


      .content{
        width: 100%;
        .skill-logo {
        width: 60px;
        height: 60px;
        box-shadow: 0 27px 24px rgba(88, 57, 231, 0.12);
        background-color: #fff;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        border-radius: 50%;
        top: -30px;
        position: absolute;

        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transition: all 2s;
        transition: all 2s;
        -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
        transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);

        &.php {
          img {
            width: 80%;
            height: 80%;
            object-fit: contain;
          }
        }

        img {
          width: 60%;
          height: 60%;
          object-fit: contain;
        }
      }

      .skill-name {
        color: #181c20;
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        margin: 0 0 1.2rem;
        width: 100%;
        align-items: flex-start;
      }

      .skill-description {
        font-size: 13px;
        line-height: 22px;
        color: #8191a2;
        width: 100%;
        align-items: flex-start;
      }
      }

      .footer{
        width: 100%;
        align-self: flex-end;
        .inner-logos{
        width: 100%;
        display: flex;
        margin-top: 15px;
        margin-bottom: 0; 
        flex-wrap: wrap;
        align-items: flex-end;

        li{
          list-style: none;
          margin-right: 10px;
          margin-bottom: 10px;
          img{
            width: auto;
            height: 40px;
          object-fit: contain;
          }
        }
      }
      }

      ${respondTo.sm`
          width: 100%;
      `}

      

      

      &:hover {
        -webkit-transform: scale(1.03);
        transform: scale(1.03);
        box-shadow: 0 23px 27px 0 rgba(29, 55, 99, 0.1);
      }
    }
  }
`;
