import React from "react";
import { IfsWrapper } from "./Ifs.styles";

const IFS = () => {

  return (
    <IfsWrapper>
      <img src="img/ifs-logo.png" />
    </IfsWrapper>
  );
};

export { IFS };
