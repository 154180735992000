import styled from "styled-components";
import { respondTo } from "../../styles/_respondTo";

export const ContactMeWrapper = styled.div`
  position: relative;
  .map-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .contact-content {
    position: relative;
  }

  .contact-form-container {
    position: relative;
    height: calc(100vh - 250px);

    ${respondTo.sm`
        height: calc(100vh - 100px);
    `}

    .contact-form {
      position: absolute;
      /* height: 50vh; */
      width: 100%;
      border-radius: 20px;
      top: 50%;
      background: linear-gradient(rgba(6, 6, 6, 0.75), rgba(45, 45, 45, 0.95));
      box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.2);
      transition: all 0.5s ease;
      transform: translateY(-50%);
      padding: 50px 32px;

      ${respondTo.sm`
            padding: 30px 15px;
        `}

      .contact-row {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        .contact-img {
          img {
            width: 40px;
            height: 40px;
            object-fit: contain;
          }
        }
        .contact-val {
          padding-left: 25px;
          font-size: 18px;
          color: #fff;
        }
      }

      p {
        font-size: 24px;
        color: #fff;

        &.mailTo {
          font-size: 18px;

          ${respondTo.sm`
            font-size: 16px;
        `}

          a {
            color: var(--primary);
          }
        }
      }
    }
  }
`;
