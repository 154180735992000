import styled from "styled-components";
import { respondTo } from "../../../../styles/_respondTo";

export const IfsWrapper = styled.div`


img{
    width: 40px;
    height: auto;
    margin: 12px 10px;

    ${respondTo.sm`
         width: 30px;
         height: auto;
         margin: 10px 5px;
    `}

}

`;
