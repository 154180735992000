import React from "react";
import { BannerWrapper } from "./Banner.styles";
import { ParticlesDiv } from "../Shared";
import { MovingAnimation } from "../Shared/Icons/MovingAnimation";

const Banner = () => {
  return (
    <BannerWrapper>
      <ParticlesDiv></ParticlesDiv>
      <div className="blob-img">
      <MovingAnimation />
        <img src="/svgs/blov-inner.svg" alt="" />
      </div>
      <div className="intro-container">
        <div className="left-panel">
          <div className="welcome-text">Hi, I'm Linuja Sinthujan</div>
          <div className="tag-line">Fullstack Developer</div>
          {/* <div className="about-me">
          Enthusiastic Software Engineer with 6+ years experience participating in the complete product development lifecycle of successfully launched applications.
          </div> */}
          <div className="about-me">
            I am technocrat IT professional offering <b>7+ years</b> of experience in
            software development and has a good engineering practice to produce
            software for new, innovative and profitable products and solutions.
            I have the ability to work on the development of software systems by
            accurately interpreting user requirements, then creating the
            required software designs, coding and testing them and finally
            integrating the software with release management.
          </div>
          <div className="buttons-area">
            
            <a
              href="pdf/Resume - Linuja Kugathas.pdf"
              rel="noopener"
              className="btn download-cv"
            >
              Download CV
            </a>
            {/* <button className="btn hire-me">Hire Me</button> */}
          </div>
        </div>
        <div className="right-panel">
          <img className="main-me-img" src="img/linuja-me-1.png" alt="" />
        </div>
      </div>
    </BannerWrapper>
  );
};

export { Banner };
