import React from "react";
import { AboutMeWrapper } from "./AboutMe.styles";
import { SectionHeading } from "../../components/Shared/SectionHeading";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const AboutMe = () => {
  return (
    <AboutMeWrapper className="dark-bg-enabled">
      <Container>
        <SectionHeading sectionHeading="About Me" />
        <div className="about-me-content">
          <Row>
            <Col md={3}>
              <div className="about-me-pic"></div>
            </Col>
            <Col md={9}>
              <div className="about-me-text">
                A Software Engineer with over <b>7 + years </b> of experience. I have
                maintained, developed and launched multiple projects from
                scratch, carrying the development of its' back-end and front-end
                codebases. My current toolset includes Java Language &
                Springboot Framework, Hibernate/JPA, REST WS, PHP Laravel, Docker and all the
                other various frameworks, libraries and technologies related to
                them. I can help with following sides of the project: developing
                the back-end and front-end, mentoring the team, estimating
                tasks, researching possible techs, leading, launching and
                monitoring the project.
              </div>

              <ul className="social-links">
                <li>
                  <a href="https://www.linkedin.com/in/linujakugathas/">
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/linuja.kugathas">
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                </li>
                {/* <li>
                  <a href="facebook.com">
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                </li> */}

                <li>
                  <a href="https://www.instagram.com/linuja5/">
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </Container>
    </AboutMeWrapper>
  );
};

export { AboutMe };
