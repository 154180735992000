import React from "react";
import { VirtusaWrapper } from "./Virtusa.styles";

const Virtusa = () => {

  return (
    <VirtusaWrapper>
      <img src="img/virtusa-logo.png" />
    </VirtusaWrapper>
  );
};

export { Virtusa };
