import styled from "styled-components";
import { respondTo } from "../../styles/_respondTo";

export const BannerWrapper = styled.div`
  position: relative;
  overflow: hidden;

  .blob-img {
    position: absolute;
    bottom: -340px;
    left: -120px;
    width: 630px;
    height: auto;

    ${respondTo.sm`
            display: none;
        `}
  }

  .intro-container {
    position: absolute;
    display: flex;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);

    ${
      respondTo.sm`
        position: absolute;
        display: block;
        transform: translateY(60px);
        top: 0;
        padding: 0 15px;
      `}

    .left-panel {
      width: 100%;
      align-self: center;
      margin-left: 20%;

      ${
        respondTo.sm`
        margin-left: 15px;
        margin-right: 15px;
        width: calc(100% - 30px);
      `}

      .welcome-text {
        font-size: 60px;
        color: var(--black-color);
        line-height: 1;
        text-align: left;

        ${respondTo.sm`
            font-size: 25px;
            margin-bottom: 15px;
        `}
      }

      .buttons-area {
        margin-top: 20px;

        .btn {
          padding: 10px 15px;
          border-radius: 5px;
          border: none;
          color: #fff;
          font-size: 15px;
          cursor: pointer;

          &.download-cv {
            background-color: var(--primary-color);
          }

          &.hire-me {
            background-color: #000000;
            margin-left: 20px;
          }
        }
      }

      .about-me {
        font-size: 15px;
        color: var(--black-color);
        line-height: 1.5;
        text-align: left;
        max-width: 600px;
        margin-top: 20px;
      }

      .tag-line {
        font-size: 45px;
        color: var(--primary-color);
        line-height: 1.5;
        text-align: left;

        ${respondTo.sm`
            font-size: 20px;
        `}
      }
    }

    .right-panel {
      margin-left: auto;

      .main-me-img {
        width: 400px;
        height: auto;
        position: relative;
        z-index: 1;

        ${
          respondTo.sm`
            width: calc(100% + 30px);
            margin: 0 -15px;
        `}
      }
    }
  }
`;
