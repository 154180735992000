import styled from "styled-components";
import { respondTo } from "../../../styles/_respondTo";

export const SectionHeadingWrapper = styled.div`

.heading-container{
    padding-top: 45px;
    padding-bottom: 30px;
    font-size: 36px;
    padding-left: 100px;
    color: #fff;
    background-image: url(img/heading-bg.png);
    background-size: contain;
    background-position: 0;
    background-repeat: no-repeat;

    ${
      respondTo.sm`
        font-size: 20px;
        padding-top: 25px;
        padding-bottom: 15px;
        padding-left: 50px;
      `
    }
}

 .slide-top {
	-webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
}

`;
