import styled from "styled-components";
import { respondTo } from "../../styles/_respondTo";

export const AboutMeWrapper = styled.div`
  position: relative;
  overflow-x: hidden;
  height: 100vh;

  .about-me-content {
    margin-top: 50px;
    padding: 20px 15px;
    background-color: #fff3;
    border-radius: 10px;
    box-sizing: border-box;

    ${respondTo.sm`
        margin-top: 20px;
    `}

    .about-me-pic {
      width: 100%;
      background-color: red;
      border-radius: 8px;
      background-image: url("img/about-me-pic.png");
      background-size: cover;

      ${respondTo.sm`
          width: 50%;
      `}

      &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
        ${respondTo.sm`
          padding-bottom: 75%;
        `}
      }
    }

    .about-me-text {
      font-size: 15px;
      color: var(--theme-white);
      line-height: 2.4;
      text-align: left;

      ${respondTo.sm`
          line-height: 1.5;
          margin-top: 10px;
      `}
    }

    .social-links {
      list-style: none;
      display: flex;
      margin-top: 20px;

      ${respondTo.sm`
          margin-bottom: 0;
      `}

      li {
        margin-right: 25px;
        a {
          font-size: 30px;
          color: var(--theme-white);
          line-height: 1.286;
          text-align: left;

          ${respondTo.sm`
            font-size: 20px;
          `}

          &:hover {
            color: var(--primary-color);
          }
        }
      }
    }
  }
`;
