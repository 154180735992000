import React from "react";
import { SectionHeadingWrapper } from "./SectionHeading.styles";





const SectionHeading = (data: any) => {

  return (
    <SectionHeadingWrapper>
        <div className="heading-container">
          {data.sectionHeading}
        </div>
    </SectionHeadingWrapper>
  );
};

export { SectionHeading };
