import React from "react";
import { AMDTWrapper } from "./AMDT.styles";

const AMDT = () => {

  return (
    <AMDTWrapper>
      <img src="img/amdt_logo.png" />
    </AMDTWrapper>
  );
};

export { AMDT };
