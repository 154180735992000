import styled from "styled-components";
import { respondTo } from "../../../styles/_respondTo";

export const LondonBGWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  .london-bg {
    height: 100vh;
    width: 100vw;
    background-image: url(img/london-bg.jpg);
    background-size: cover;
    position: relative;

    .cr {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
  }
`;
