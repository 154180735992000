import {  createGlobalStyle } from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';

export const GlobalStyle = createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        font-family: 'Noto Sans', sans-serif;
        box-sizing: border-box;
        outline: none;       
    }

    :root {
        --primary-color: #4db2af;
        --black-color: #202020;
        --secondary-color: #4646f3;
        --tertiary-color: #65c3de;
        --theme-white: #fff;

    }

    a{
        text-decoration: none;
    }

    body{
        overflow-x: hidden
    }

    .dark-bg-enabled{
        background-image: url('img/about-me-particle-bg.png');
        background-size: cover;
        background-color: #181c28;
        padding: 30px 0;

        &.no-height-resc{
            min-height: 100vh;
            height: auto;
        }
    }
`;