import React from "react";
import { ExperienceWrapper } from "./Experience.styles";
import { SectionHeading } from "../../components/Shared/SectionHeading";
import { Container } from "react-bootstrap";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import { CreativeCliks } from "../../components/Shared/Icons/CreativeCliks";
import { IFS } from "../../components/Shared/Icons/Ifs";
import { Hoxton } from "../../components/Shared/Icons/Hoxton";
import { Virtusa } from "../../components/Shared/Icons/Virtusa";
import { RayZay } from "../../components/Shared/Icons/Rayzay";

const Experience = () => {
  return (
    <ExperienceWrapper className="dark-bg-enabled no-height-resc">
      <Container>
        <SectionHeading sectionHeading="Experience" />

        <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="August 2020 - present"
            iconStyle={{ background: "#fff", color: "#fff" }}
            icon={<Hoxton />}
          >
            <h3 className="vertical-timeline-element-title">
              Senior Software Engineer
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              HOXTON CAPITAL - DUBAI
            </h4>
            <p>
              Involved in implementing and architecting the application which
              served client needs in a finance institution using PHP Laravel,
              Angular 9 and MySQL
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="March 2020 - July 2020"
            iconStyle={{ background: "#fff", color: "#fff" }}
            icon={<RayZay />}
          >
            <h3 className="vertical-timeline-element-title">
              Senior Software Engineer
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              RAYZAY SOLUTIONS (PVT) LTD
            </h4>
            <p>
              Architected and implemented a new Management software for a
              leading water distribution company using Angular 9 as front end
              and Java as Backend.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="October 2019 - January 2020"
            iconStyle={{ background: "#893d90", color: "#fff" }}
            icon={<IFS />}
          >
            <h3 className="vertical-timeline-element-title">
              Senior Software Engineer
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              IFS, Sri Lanka
            </h4>
            <p>
              Involved in implementing new features to the framework. Analyzed
              new requirements and involved in the Research and development of
              those.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="October 2018 - October 2019"
            iconStyle={{ background: "#fff", color: "#fff" }}
            icon={<Virtusa />}
          >
            <h3 className="vertical-timeline-element-title">
              Associate Consultant
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Virtusa, Sri Lanka
            </h4>
            <p>
              Analyze and resolve complex technical problems. Collaborate and
              communicate effectively with others as well as manage stakeholder
              expectations in order to solve technical problems and provide
              technical solutions.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="January 2017 - October 2018"
            iconStyle={{ background: "#fff", color: "#fff" }}
            icon={<Virtusa />}
          >
            <h3 className="vertical-timeline-element-title">
              Senior Software Engineer
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Virtusa, Sri Lanka
            </h4>
            <p>
              Development & Maintenance of systems using J2EE, Spring MVC as
              programming language. Implemented & Maintained web applications
              SOAP web services. Functional testing in various System releases
              and Implement test and defect reports using organization standard
              tools.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="December 2015 - January 2017"
            iconStyle={{ background: "#fff", color: "#fff" }}
            icon={<Virtusa />}
          >
            <h3 className="vertical-timeline-element-title">
              Software Engineer
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Virtusa, Sri Lanka
            </h4>
            <p>
              Maintenance of systems using J2EE, Core Java, and EJB as
              programming language with the assistance from tech leads.
              Developed the test automation suite for the application using
              Selenium.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="January 2014 - December 2015"
            iconStyle={{ background: "#fff", color: "#fff" }}
            icon={<CreativeCliks />}
          >
            <h3 className="vertical-timeline-element-title">
              Lead Software Engineer
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Creative Cliks (PVT) Ltd.
            </h4>
            <p>
              Architectured, Developed & Maintained system using PHP, Laravel as
              programming language. Designed Database using MySQL. Carried out
              functional testing and performed various System releases. Prepared
              system test and defect reports using organization standard tools
            </p>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </Container>
    </ExperienceWrapper>
  );
};

export { Experience };
