import styled from "styled-components";
import { respondTo } from "../../styles/_respondTo";

export const EducationWrapper = styled.div`
  position: relative;
  .vertical-timeline-element-date {
    color: #fff;

    ${respondTo.sm`
       color: #000;
    `}
  }

  h3{
    font-size: 20px;
    ${respondTo.sm`
        font-size: 20px;
    `}
  }

  h4{
    color: var(--primary-color);
    font-size: 16px;
    margin-top: 15px;
    ${respondTo.sm`
        font-size: 15px;
    `}
  }



  .vertical-timeline-element-icon {
    box-shadow: none;
    overflow: hidden;
  }

  .vertical-timeline-element-content{
    ${respondTo.sm`
        margin-left: 50px;
    `}
  }
`;
