import React from "react";
import { RayzayWrapper } from "./Rayzay.styles";

const RayZay = () => {

  return (
    <RayzayWrapper>
      <img src="img/rayzay.png" />
    </RayzayWrapper>
  );
};

export { RayZay };
