import React from "react";
import { LondonBGWrapper } from "./LondonBG.styles";

const LondonBG = (data: any) => {
  return (
    <LondonBGWrapper>
      <div className="london-bg">
        <div className="cr">
          Picture By :{" "}
          <a href="https://www.pexels.com/@davideibiza/">Davide Baraldi</a>
        </div>
      </div>
    </LondonBGWrapper>
  );
};

export { LondonBG };
