import React from "react";
import { ParticleWrapper } from "./Particles.styles";
import Particles from 'react-particles-js';

const ParticlesDiv = () => {
  return (
    <ParticleWrapper>
     <Particles className="p-Zone"
    params={{
	    "particles": {
	        "number": {
	            "value": 15,
	            "density": {
	                "enable": true,
	                "value_area": 800
	            }
			},
			"opacity":{
				value: 0.5
			},

	        "line_linked": {
	            "enable": false
	        },
	        "move": {
              "speed": 2
	        },
	        "shape": {
	            "type": [
	                "image"
	            ],
	            "image": [
	                {
	                    "src": 'img/triangle-1.png',
	                    "height": 20,
	                    "width": 23
	                },
	                {
	                    "src": 'img/square-1.png',
	                    "height": 20,
	                    "width": 20
	                },
	                {
	                    "src": 'img/square-4.png',
	                    "height": 20,
	                    "width": 20
	                },
	                {
	                    "src": 'img/square-3.png',
	                    "height": 20,
	                    "width": 20
	                },
	                {
	                    "src": 'img/zigzag-2.png',
	                    "height": 20,
	                    "width": 20
	                }
	            ]
          },
          "rotate": {
            "animation": {
              "enable": true,
              "speed": 1,
              "sync": false
            },
            "random": false,
            "value": 0
          },
	        "color": {
	            "value": "#CCC"
	        },
	        "size": {
	            "value": 30,
	            "random": false,
	            "anim": {
	                "enable": true,
	                "speed": 4,
	                "size_min": 10,
	                "sync": true
	            }
	        }
	    },
	    "retina_detect": false
	}} />
    </ParticleWrapper>
  );
};

export { ParticlesDiv };
