import React from "react";
import { SkillsWrapper } from "./Skills.styles";
import { SectionHeading } from "../../components/Shared/SectionHeading";
import { Container } from "react-bootstrap";

const Skills = () => {
  return (
    <SkillsWrapper className="dark-bg-enabled">
      <Container>
        <SectionHeading sectionHeading="Skills" />

        <div className="skills-section">
          <div className="skills-card">
            <div className="content">
              <div className="skill-logo">
                <img src="svgs/java.svg" alt="" />
              </div>
              <div className="skill-name">Java </div>
              <div className="skill-description">
                Throughtout my experience, I've architectured and built
                applications using Java8 and Java11 together with J2EE,
                following OOP concepts and industry standards.
              </div>
            </div>
          </div>
          <div className="skills-card">
            <div className="content">
              <div className="skill-logo">
                <img src="svgs/spring.svg" alt="" />
              </div>
              <div className="skill-name">Spring </div>
              <div className="skill-description">
                I have intense experience using the frameworks Spring MVC,
                Spring Batch, Spring Boot, Spring data JPA, Spring Security and
                Spring Test.
              </div>
            </div>
          </div>
          <div className="skills-card">
            <div className="content">
              <div className="skill-logo">
                <img src="img/orm.png" alt="" />
              </div>
              <div className="skill-name">ORM</div>
              <div className="skill-description">
                My experience with ORM tools includes Hibernate along with JPA
                for relational database persistence.
              </div>
            </div>
          </div>
          <div className="skills-card">
            <div className="content">
              <div className="skill-logo php">
                <img src="svgs/php.svg" alt="" />
              </div>
              <div className="skill-name">PHP </div>
              <div className="skill-description">
                Have a very good grasp of Laravel Framework. Architectured,
                built and deployed projects in client environment.
              </div>
            </div>
            <div className="footer">
              <ul className="inner-logos">
                <li>
                  <img src="svgs/laravel.svg" alt="" />
                </li>
              </ul>
            </div>
          </div>
          <div className="skills-card">
            <div className="content">
            <div className="skill-logo">
              <img src="svgs/web-services.svg" alt="" />
            </div>
            <div className="skill-name">Web Services </div>
            <div className="skill-description">
              I have build and architectured REST APIs using REST Webservice,
              OpenAPI, OData and executed API tests using SOAP UI tool.
            </div>
            </div>
            <div className="footer"></div>
           
          </div>
          <div className="skills-card">
            <div className="content">
              <div className="skill-logo">
                <img src="img/frontend.png" alt="" />
              </div>
              <div className="skill-name">Frontend </div>
              <div className="skill-description">
                I have used JSP, Javascript, Angular, HTML, CSS, Java Swing
                along with Bootstrap in front development.
              </div>
            </div>
            <div className="footer">
              <ul className="inner-logos">
                <li>
                  <img src="svgs/jsp.svg" alt="" />
                </li>
                <li>
                  <img src="svgs/angular.svg" alt="" />
                </li>
                <li>
                  <img src="svgs/html.svg" alt="" />
                </li>
                <li>
                  <img src="svgs/css.svg" alt="" />
                </li>
                <li>
                  <img src="svgs/bootstrap.svg" alt="" />
                </li>
                <li>
                  <img src="svgs/javascript.svg" alt="" />
                </li>
              </ul>
            </div>
          </div>

          <div className="skills-card">
            <div className="content">
              <div className="skill-logo">
                <img src="svgs/malware.svg" alt="" />
              </div>
              <div className="skill-name">Unit Testing </div>
              <div className="skill-description">
                All the project I worked on had been properly tested and
                executed. These testings included the tools such as JUnit,
                Jmeter, Selenium, TestNG, Mockito etc for unit testing and
                Spring Test to cover Functional and Acceptance Tests.
              </div>
            </div>
            <div className="footer">
              <ul className="inner-logos">
                <li>
                  <img src="img/jUnit.png" alt="" />
                </li>
                <li>
                  <img src="svgs/jMeter.svg" alt="" />
                </li>
                <li>
                  <img src="img/selenium.png" alt="" />
                </li>
                <li>
                  <img src="img/testNG.png" alt="" />
                </li>
                <li>
                  <img src="img/mockito.png" alt="" />
                </li>
              </ul>
            </div>
          </div>
          <div className="skills-card">
            <div className="content">
              <div className="skill-logo">
                <img src="img/devops.png" alt="" />
              </div>
              <div className="skill-name">Dev Ops & Deployments</div>
              <div className="skill-description">
                I had the opportunity to deploy and maintain the projects I
                worked on. This helped me to gain some DevOps skills as well.
                <br></br>
                <br></br>
                Docker, JBoss, WebSpehere, Nexus and AWS are some of the tools
                I'm familar with and used throughout the process.
              </div>
            </div>
            <div className="footer">
              <ul className="inner-logos">
                <li>
                  <img src="svgs/docker.svg" alt="" />
                </li>
                <li>
                  <img src="img/jboss.png" alt="" />
                </li>
                <li>
                  <img src="svgs/websphere.svg" alt="" />
                </li>
                <li>
                  <img src="svgs/aws.svg" alt="" />
                </li>
                <li>
                  <img src="img/nexus.png" alt="" />
                </li>
              </ul>
            </div>
          </div>
          <div className="skills-card">
            <div className="content">
              <div className="skill-logo">
                <img src="svgs/source-control.svg" alt="" />
              </div>
              <div className="skill-name">Source Control</div>
              <div className="skill-description">
                GitHub, Perforce, Git, GitLab and BitBucket are the source
                control tools I'm familiar with.
              </div>
            </div>
            <div className="footer">
              <ul className="inner-logos">
                <li>
                  <img src="svgs/github-1.svg" alt="" />
                </li>
                <li>
                  <img src="img/perforce.png" alt="" />
                </li>
                <li>
                  <img src="svgs/git-icon.svg" alt="" />
                </li>
                <li>
                  <img src="svgs/gitlab.svg" alt="" />
                </li>
                <li>
                  <img src="svgs/bitbucket.svg" alt="" />
                </li>
              </ul>
            </div>
          </div>

          <div className="skills-card">
            <div className="content">
              <div className="skill-logo">
                <img src="svgs/database.svg" alt="" />
              </div>
              <div className="skill-name">Database</div>
              <div className="skill-description">
                Different projects required different databases based on the
                requirements. I was and will be able to use MSSQL, MySQL, DB2,
                PostgreSQL and PL/SQL along with JDBC and Hibernate to interact
                between Java and Database.
                <br></br>I also have configured and worked with non relations
                databased on few NodeJs projects which includes MongoDB and
                FireBase.
              </div>
            </div>
            <div className="footer">
              <ul className="inner-logos">
                <li>
                  <img src="svgs/sql.svg" alt="" />
                </li>
                <li>
                  <img src="svgs/mysql.svg" alt="" />
                </li>
                <li>
                  <img src="svgs/postgresql.svg" alt="" />
                </li>
                <li>
                  <img src="svgs/firebase.svg" alt="" />
                </li>
                <li>
                  <img src="svgs/mongodb.svg" alt="" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </SkillsWrapper>
  );
};

export { Skills };
