import React from "react";
import { CurtinWrapper } from "./Curtin.styles";

const Curtin = () => {

  return (
    <CurtinWrapper>
      <img src="svgs/curtin-university.svg" />
    </CurtinWrapper>
  );
};

export { Curtin };
