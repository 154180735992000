import { css } from "styled-components";
import { breakpoints } from "./_variables";

export const respondTo = Object.keys(breakpoints).reduce(
    (accumulator: any, label) => {
        accumulator[label] = (...args: any) => css `
            @media (max-width: ${breakpoints[label]}) {
                ${css(args)};
            }
        `;
        return accumulator;
    }, {}
);