import React from "react";
import { RayzayWrapper } from "./Hoxton.styles";

const Hoxton = () => {

  return (
    <RayzayWrapper>
      <img src="img/hoxton.png" />
    </RayzayWrapper>
  );
};

export { Hoxton };
