import React from 'react';
import { Banner } from '../Banner';
import { Fullpage, Slide, HorizontalSlider } from 'fullpage-react';
import { AboutMe } from '../../pages/AboutMe';
import { GlobalStyle } from '../../styles/GlobalStyles';
import { Experience } from '../../pages/Experience';
import { Skills } from '../../pages/Skills';
import { Education } from '../../pages/Education';
import { ContactMe } from '../../pages/ContactMe';

const fullPageOptions = {
  scrollSensitivity: 7,
  touchSensitivity: 7,
  scrollSpeed: 500,
  hideScrollBars: true,
  enableArrowKeys: true,
  slides: {}
};


const slides = [
  <Slide> 
    <Banner></Banner>
  </Slide>,
  <Slide>
    <AboutMe></AboutMe>
  </Slide>
  // <Slide>
  //   <Experience></Experience>
  // </Slide>,
  // <Slide>
  //   <Skills></Skills>
  // </Slide>,
  // <Slide> Slide 5 </Slide>
];
fullPageOptions.slides = slides;


function App() {
  return (
    <>
      <GlobalStyle />     
      {/* <Fullpage {...fullPageOptions} /> */}
      <Banner></Banner>
      <AboutMe></AboutMe>
      <Experience></Experience>
      <Skills></Skills>
      <Education></Education>
      <ContactMe></ContactMe>
    </>
  );
}

export default App;
