import React from "react";
import { EdexcelWrapper } from "./Edexcel.styles";

const Edexcel = () => {

  return (
    <EdexcelWrapper>
      <img src="img/edexcel.png" />
    </EdexcelWrapper>
  );
};

export { Edexcel };
