import React from "react";
import { EducationWrapper } from "./Education.styles";
import { SectionHeading } from "../../components/Shared/SectionHeading";
import { Container } from "react-bootstrap";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import { Virtusa } from "../../components/Shared/Icons/Virtusa";
import { Curtin } from "../../components/Shared/Icons/Curtin";
import { SLIIT } from "../../components/Shared/Icons/Sliit";
import { AMDT } from "../../components/Shared/Icons/AMDT";
import { Edexcel } from "../../components/Shared/Icons/Edexcel";

const Education = () => {
  return (
    <EducationWrapper className="dark-bg-enabled no-height-resc">
      <Container>
        <SectionHeading sectionHeading="Education" />

        <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="December 2012 - January 2016"
            iconStyle={{ background: "#c18f05" , color: "#fff" }}
            icon={<Curtin />}
          >
            <h3 className="vertical-timeline-element-title">
            BSC in Computer Science Specialization in Software Engineering
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
            CURTIN UNIVERSITY OF AUSTRALIA
            </h4>
           
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="January 2011 - December 2012"
            iconStyle={{ background: "#fff", color: "#fff" }}
            icon={<Edexcel />}
          >
            <h3 className="vertical-timeline-element-title">
            G.C.E Advanced Level - London
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
            Edexcel
            </h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="September 2012"
            iconStyle={{ background: "#fff", color: "#fff" }}
            icon={<AMDT />}
          >
            <h3 className="vertical-timeline-element-title">
            Creative Graphic Productions 
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
            Academy of Multimedia Design & Technology
            </h4>
            
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="June 2011"
            iconStyle={{ background: "#0e46a1", color: "#fff" }}
            icon={<SLIIT />}
          >
            <h3 className="vertical-timeline-element-title">
            Industrial Training in Windows Network Administration
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
            Turnkey IT Training, Colombo
            </h4>
           
          </VerticalTimelineElement>
         
        </VerticalTimeline>
      </Container>
    </EducationWrapper>
  );
};

export { Education };
